import Bugsnag, { Config } from '@bugsnag/js'

// NOTE: テスト実行時の notifyReleaseStages 警告が邪魔だからログ無効化
const loggerOptions = (): Pick<Config, 'logger'> => {
  // eslint-disable-next-line unicorn/no-null
  return import.meta.env.RAILS_ENV === 'test' ? { logger: null } : {}
}

Bugsnag.start({
  releaseStage: import.meta.env.RAILS_ENV,
  apiKey: '350a7e19b8cf92653ca934399e7e1da8',
  enabledReleaseStages: ['production'],
  ...loggerOptions(),
})
